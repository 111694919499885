
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        
















































































.promo-perks {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.promo-perks__speedlines {
  @include get-all-space;
  @include center-xy;

  z-index: -1;

  .speedlines__item,
  [class*='speedlines__item--'] {
    @include mq($until: m) {
      opacity: 0;
    }
  }
}

.promo-perks__items {
  --slide-gap: #{$spacing};

  position: relative;
  width: 100%;

  ::v-deep {
    .flickity-viewport,
    .flickity-slider {
      position: relative;
      width: 100%;
    }

    .flickity-page-dots .dot {
      &::before {
        background: $c-blue-light-pale;
      }

      &.is-selected::before {
        background: $c-blue-pale;
      }
    }

    .carousel__item {
      z-index: 1;
      flex-grow: 1;
      width: 100%;

      @include mq(l) {
        flex-shrink: 1;
      }
    }
  }

  @include mq(l) {
    margin-top: $spacing * 0.6;
    margin-bottom: $spacing * 1.25;
  }

  @include mq(l) {
    --slide-gap: calc(#{$spacing} * 1.5);
  }
}

.promo-perks__item {
  @extend %text-center;

  position: relative;
  overflow: hidden;
  height: 100%;
  padding: $spacing * 1.5;
  color: $white;
  border-radius: 20px;

  &::before {
    @include get-all-space;

    z-index: -1;
    content: '';
    display: block;
    background-color: rgba($white, 0.06);
    backdrop-filter: blur(15px);
  }
}

.promo-perks__item__icon {
  // prettier-ignore
  @include fluid(height, (s: 40px, l: 65px));

  // prettier-ignore
  @include fluid(width, (s: 40px, l: 65px));

  fill: $white;
}

.promo-perks__item__title {
  // prettier-ignore
  @include fluid(font-size, (s: 18px, l: 22px));
}

.promo-perks__item__text {
  // prettier-ignore
  @include fluid(font-size, (s: 14px, l: 18px));
}

.promo-perks__link {
  margin-top: $spacing * 2;
}
