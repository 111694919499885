
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



























































































































































































































.tv-offer-switch {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
  background-color: $white;

  &.is-dark {
    color: $white;
    background-color: transparent;
  }

  @include mq(l) {
    margin-top: $spacing * 3;
    padding-top: $spacing * 3;
    padding-bottom: $spacing * 3;
    font-size: 1.6rem;
  }
}

.tv-offer-switch-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tv-offer-switch__title {
  @extend %text-center;

  // prettier-ignore
  @include fluid(font-size,(s: 28px,xl: 54px,));

  max-width: 30rem;
  margin: 0 auto;
  font-weight: 400;

  strong {
    font-weight: 700;
  }

  @include mq(l) {
    max-width: 80rem;
  }
}

.tv-offer-switch__subtitle {
  @extend %text-center;

  // prettier-ignore
  @include fluid(font-size,(s: 15px,xl: 18px,));

  font-weight: 400;

  strong {
    font-weight: 700;
    text-align: center;
  }

  @include mq(l) {
    width: 64%;
    margin: 20px auto 40px;
  }
}

.tv-offer-switch__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 35rem;
  padding: 0 3rem;

  &::before {
    @include center-xy;

    z-index: 1;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s;

    .has-entered & {
      opacity: 1;
    }
  }

  @include mq(s) {
    width: 42.8rem;
  }

  @include mq(l) {
    width: auto;
    padding: 0 6rem;
  }
}

.tv-offer-switch__items {
  ::v-deep {
    .carousel__scroller {
      --slide-gap: 0;
    }

    .carousel__item {
      &:nth-child(2) {
        /* stylelint-disable-next-line max-nesting-depth */
        .tv-offer-switch__item::before {
          content: url('../../inc/svg/tv/offer-arrow.svg');
        }
      }

      &:nth-child(3) {
        /* stylelint-disable-next-line max-nesting-depth */
        .tv-offer-switch__item::before {
          content: url('../../inc/svg/tv/offer-arrow-2.svg');
        }
      }
    }

    @include mq(l) {
      .carousel__scroller {
        justify-content: space-between;
      }

      .carousel__item {
        flex-shrink: 1;
        width: 32%;
      }
    }
  }

  @include mq(l) {
    margin-top: $spacing * 0.6;
    margin-bottom: $spacing * 1.25;
  }
}

.tv-offer-switch__item__picture-outer {
  position: relative;
  width: 100%;
  height: 23rem;
}

.tv-offer-switch__item__picture {
  @include image-fit(contain);
}

.tv-offer-switch__item__content {
  @extend %text-center;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.tv-offer-switch__item__step {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  font-size: 1.5rem;
  font-weight: 600;
  border: 1px solid $c-blue-pale;
  border-radius: 4rem;
}

.tv-offer-switch__btn {
  margin-top: $spacing;
}

.tv-offer-switch__already-client {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $spacing * 2.5;

  @include mq(l) {
    margin-top: $spacing * 4;
  }
}

.tv-offer-switch__already-client__title {
  margin: 0;
}

.tv-offer-switch__already-client__text {
  @extend %text-center;

  ::v-deep a {
    color: $c-pink-medium;
  }
}
