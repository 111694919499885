
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



















































































































/* stylelint-disable declaration-no-important */

.smartphones-listing,
[class*='smartphones-listing--'] {
  position: relative;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  background-color: $white;

  &.flickity-enabled {
    display: block;
  }

  ::v-deep {
    .flickity-viewport,
    .flickity-slider {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      min-height: 40rem;

      @include mq(l) {
        min-height: 40rem;
      }
    }

    .flickity-button {
      @include center-y;

      &.next {
        right: 0;
        left: initial;
      }
    }
  }

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10rem;
  }
}

.smartphones-listing__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 0 $spacing;
  padding: $spacing;
  list-style-type: none;
  border-radius: 20px;
  transition: box-shadow $ease 0.3s;

  &:hover {
    @extend %box-shadow;
  }

  ::v-deep .smartphone-item__misc {
    top: auto !important;
    right: 0;
    bottom: 5% !important;
    left: auto !important;
    min-width: 10rem !important;
    padding: $spacing !important;

    @include mq(l) {
      min-width: 15rem !important;
    }
  }

  ::v-deep .smartphone-item__misc__name {
    display: none;
  }

  ::v-deep .smartphone-item__picture-outer {
    width: 100% !important;
    height: auto !important;
  }

  ::v-deep .smartphone-item__picture {
    position: static;
  }

  ::v-deep .smartphone-item__misc__offer {
    margin-bottom: 0 !important;
  }

  @include mq(xs) {
    margin: 0 0 $spacing * 2;
  }

  @include mq(l) {
    width: calc((100% - 20rem) / 3);
    padding: $spacing * 1.5;
  }

  @include mq(xl) {
    margin: 0 0 $spacing * 3;
  }
}

.smartphones-listing__item__brand,
.smartphones-listing__item__model {
  @extend %fw-bold;

  font-family: $ff-alt;
  text-align: center;
}

.smartphones-listing__item__brand {
  margin: 0;
  font-size: 1.8rem;
  line-height: 1.28;

  @include mq(l) {
    font-size: 2.4rem;
  }
}

.smartphones-listing__item__model {
  margin: 0 0 $spacing;
  font-size: 2.2rem;
  line-height: 1.2;

  @include mq(l) {
    font-size: 3.2rem;
  }
}

.smartphones-listing__item__device,
.smartphones-listing__item__device.is-highlight {
  max-width: 90%;
  margin: 0 auto $spacing;

  @include mq(s) {
    max-width: 70%;
  }

  @include mq(m) {
    max-width: 80%;
  }

  @include mq(l) {
    max-width: 20rem;
    height: 100%;
  }
}

.smartphones-listing__item__more {
  @extend %fw-semi;

  display: flex;
  margin: 0 auto;
  color: $white;
  transition: opacity $ease 0.3s;

  @include mq(l) {
    opacity: 0;

    .smartphones-listing__item:hover & {
      opacity: 1;
    }
  }
}
