
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        










































































.promo-gift {
  position: relative;
  z-index: 2;
  max-width: 168rem;
  margin: 0 auto;
  padding-bottom: 4rem;

  &.hidden > * {
    opacity: 0;
  }
}

.promo-gift__title {
  @extend %text-center;

  color: $c-light;
  padding: 0 $spacing * 1.5;
}

.promo-gift__text {
  @extend %text-center;

  max-width: 30em;
  margin: auto;
  padding: 0 $spacing * 1.5;
  color: $c-light;
}

.promo-gift__cards {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;
  margin: 3rem;

  @include mq(m) {
  }

  @include mq(xl) {
    padding-bottom: 6rem;
  }
}

.promo-gift__cards__item {
  @include mq($until: m) {
    grid-column: span 2;
  }

  &.is-highlight {
    z-index: -1;
    grid-column: 1 / span 2;
    grid-row: 1 / 1;
  }
}
